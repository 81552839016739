//
// modal.scss
//

// IE fix

.modal-content > * {
  flex-shrink: 0;
}


// Body

.modal-body {
  flex-grow: 0;
}


// Header

.modal-header {
  padding: $modal-header-padding-y $modal-header-padding-x;
}


// Footer

.modal-footer {
  justify-content: flex-start;
  padding: $modal-footer-padding-y $modal-footer-padding-x;
  border-width: 0;
}