//
// ratio.scss
//

.ratio-item-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
